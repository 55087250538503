import { State } from "ketting"
import Resource from "ketting/dist/resource"
import { HALClient } from "modules/common/HALClient"
import { linksToRelationsObject } from "modules/common/helpers"
import { Uri } from "modules/common/types"

interface IMerchantApiRequestOptions {
    token?: string
    contentType?: string
    noCache?: boolean
    resetCache?: boolean
    acceptLanguage?: string
}

export interface ParsedResponse<Relations = unknown, EmbeddedResourceType = unknown> {
    embeddedResources: EmbeddedResourceType | null
    relations: Relations
}

type PropertiesOf<EmbeddedResourceType = unknown> = keyof EmbeddedResourceType

type EmbeddedResource<T = unknown> = T

export interface MerchantApiResponse<
    ResourceType,
    Relations = unknown,
    EmbeddedResourceType = unknown
> extends ParsedResponse<Relations, EmbeddedResourceType> {
    repr: ResourceType
}

class MerchantApiRequest<ResourceType, Relations = unknown, EmbeddedResourceType = any> {
    public resource: Resource<ResourceType>
    private _contentType: string | undefined
    private _acceptLanguage: string | undefined
    private _repr: State<ResourceType> | undefined | void = undefined

    constructor(uri: Uri, options?: IMerchantApiRequestOptions) {
        if (options && options.token) {
            HALClient.setToken(options.token)
        }

        this.resource = HALClient.go(uri)

        if (options && options.noCache && HALClient.resourceCache) {
            this.resource.clearCache()
        }

        if (options && options.resetCache && HALClient.resourceCache) {
            this.resource.client.cache.clear()
        }

        if (options && options.contentType) {
            this._contentType = options.contentType
        }

        if (options && options.acceptLanguage) {
            this._acceptLanguage = options.acceptLanguage
        }
    }

    public async get() {
        const headers: Headers = new Headers()

        if (this._contentType) {
            headers.set("Accept", this._contentType)
        }

        if (this._acceptLanguage) {
            headers.set("Accept-Language", this._acceptLanguage)
        }

        this._repr = await this.resource.get({
            headers,
        })

        const { relations, embeddedResources } = await this.parseResponse()

        return {
            embeddedResources,
            relations,
            repr: this._repr.data,
        } as MerchantApiResponse<ResourceType, Relations, EmbeddedResourceType>
    }

    public async post<TDto = object>(body: TDto, noFollow?: boolean) {
        if (noFollow) {
            this._repr = await this.resource.post({ data: body })
        } else {
            const newResource = await this.resource.postFollow({ data: body })
            if (newResource) {
                this.resource = newResource
            }
            this._repr = await this.resource.get()
        }
        const { relations, embeddedResources } = await this.parseResponse()

        return {
            embeddedResources,
            relations,
            repr: this._repr.data,
        } as MerchantApiResponse<ResourceType, Relations, EmbeddedResourceType>
    }

    public async patch(body: object) {
        this._repr = await this.resource.patch({ data: body })
        const { relations, embeddedResources } = await this.parseResponse()

        return {
            embeddedResources,
            relations,
            repr: this._repr ? this._repr.data : undefined,
        } as MerchantApiResponse<ResourceType, Relations, EmbeddedResourceType>
    }

    public async put(body: ResourceType) {
        return await this.resource.put({ data: body })
    }

    public async delete() {
        return await this.resource.delete()
    }

    private async parseResponse(): Promise<ParsedResponse<Relations, EmbeddedResourceType>> {
        if (!this._repr) {
            throw Error(`No State to parse`)
        }

        const links = this._repr.links.getAll()
        const relations = linksToRelationsObject(links) as any
        const linksWithoutSelf = links.filter((l) => l.rel !== "self")

        let prevRel
        const embeddedResources: {
            [property in PropertiesOf<EmbeddedResourceType>]: EmbeddedResource | EmbeddedResource[]
        } = {} as {
            [property in PropertiesOf<EmbeddedResourceType>]: EmbeddedResource | EmbeddedResource[]
        }
        for (const item of this._repr.getEmbedded()) {
            const itemRel = linksWithoutSelf.find((l) => l.href === item.uri)
                ?.rel as PropertiesOf<EmbeddedResourceType>
            if (!itemRel) {
                continue
            }

            if (prevRel === undefined) {
                prevRel = itemRel
            }

            if (embeddedResources[itemRel] && !Array.isArray(embeddedResources[itemRel])) {
                // Convert embeddedResources[itemRel] to Array
                embeddedResources[itemRel] = [embeddedResources[itemRel] as EmbeddedResource]
            }

            const embeddedResourcesProperty = embeddedResources[itemRel]
            if (!embeddedResourcesProperty) {
                embeddedResources[itemRel] = {
                    ...item.data,
                    relations: linksToRelationsObject(item.links.getAll()),
                }
            } else if (Array.isArray(embeddedResourcesProperty)) {
                embeddedResourcesProperty.push({
                    ...item.data,
                    relations: linksToRelationsObject(item.links.getAll()),
                })
            }
        }

        return {
            embeddedResources: embeddedResources as unknown as EmbeddedResourceType,
            relations,
        }
    }
}

export { MerchantApiRequest }
export default MerchantApiRequest
